import React, { useRef } from "react";
import styles from "./carouselsmall.module.css";
import { useNavigate } from "react-router-dom";
function CarouselSmall({ names, images }) {
  const scrollContRef = useRef(null);
  const navigate = useNavigate();
  const handleRight = () => {
    if (scrollContRef.current) {
      scrollContRef.current.scroll({
        left: scrollContRef.current.scrollLeft + 250,
        behavior: "smooth",
      });
    }
  };
  const handleLeft = () => {
    if (scrollContRef.current) {
      scrollContRef.current.scroll({
        left: scrollContRef.current.scrollLeft - 250,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div ref={scrollContRef} className={styles.imgCont}>
          {images.map((i, idx) => {
            return (
              <div className={styles.imgCard}>
                <div>
                  {" "}
                  <img
                    onClick={() => navigate(`${i.type}`)}
                    src={i.pic}
                    alt={i.type}
                  />{" "}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`${i.type}`)}
                  className={styles.imgName}
                >
                  {i.type}{" "}
                </div>
              </div>
            );
          })}
        </div>
        <div onClick={handleLeft} className={styles.leftArrowCont}>
          <div className={styles.leftArrow}></div>
        </div>
        <div onClick={handleRight} className={styles.rightArrowCont}>
          <div className={styles.rightArrow}></div>
        </div>
      </div>
    </>
  );
}

export default CarouselSmall;
