import React, { useEffect } from "react";
import Carousel from "../../components/Carousel/Carousel";
import styles from "./hompage.module.css";
import Chilli from "../../assets/product-imgs/chilli.png";
// import Makhana from "../../assets/product-imgs/Makhana.png";
import DryFruits from "../../assets/product-imgs/dry-fruits.png";
import Pulses from "../../assets/product-imgs/pulses.png";
import Cereals from "../../assets/product-imgs/cereals.png";
import whyUsImg from "../../assets/whyUsImg.png";
import img1 from "../../assets/carousel-img/1.jpg";
import img2 from "../../assets/carousel-img/2.jpeg";
import img3 from "../../assets/carousel-img/3.jpeg";
import CarouselSmall from "../../components/CarouselSmall/CarouselSmall";
import useScreenSize from "../../customHooks/useScreenSize";
import img1Mobile from "../../assets/mobileImages/1.jpeg";
import img2Mobile from "../../assets/mobileImages/2.jpeg";
import img3Mobile from "../../assets/mobileImages/3.jpeg";
// import bg from "../../assets/whyUSbg.jpg";
function HomePage() {
  // const types = ["all", "spices", "dryFruits", "cereals", "pulses", "makhana"];
  // const [cat, setCat] = useState("all");
  // const images1 = [img1, img2, img3];
  const screenSize = useScreenSize();
  const images1 = [img1, img2, img3];
  const images1Mobile = [img1Mobile, img2Mobile, img3Mobile];
  const names = ["Spices", "Makhana", "Dry Fruits", "Pulses", "Cereals"];
  const images2 = [
    {
      type: "Rice Facewash",
      pic: Chilli,
    },
    {
      type: "Face Scrub",
      pic: DryFruits,
    },
    {
      type: "Moisturizer",
      pic: Pulses,
    },
    {
      type: "Alovera Neem Facewash",
      pic: Cereals,
    },
    {
      type: "Sunscreen",
      pic: Cereals,
    },
    {
      type: "Night Cream",
      pic: Cereals,
    },
    {
      type: "Face Serum",
      pic: Cereals,
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.slider}>
          <Carousel
            text={true}
            images={screenSize < 600 ? images1Mobile : images1}
          />
        </div>

        <div style={{ marginTop: "50px" }} className={styles.slider}>
          <div className={styles.prodRange}>
            <strong>Our Product Range</strong>
          </div>
          <CarouselSmall names={names} images={images2} />
        </div>

        <div className={styles.whyUsCaption}>Why Choose Us?</div>
        <div className={styles.whyUs}>
          <div className={styles.left}>
            <img src={whyUsImg} alt="why us" />
          </div>
          <div className={styles.right}>
            {/* <div className={styles.rightCap}>FEATURES OF FARMROOTS</div> */}
            <div className={styles.info}>
              With the increasing demand for safe and sustainable products, we
              are committed to supporting local farmers and communities and
              delivering quality products to our customers.
            </div>
            <div className={styles.points}>
              <ul>
                <li>
                  Natural Ingredients: We source the highest quality botanical
                  extracts and natural oils.
                </li>
                <li>
                  Cruelty-Free: We are committed to cruelty-free practices and
                  never test our products on animals.
                </li>
                <li>
                  Eco-Friendly: Our packaging is recyclable, and we strive to
                  minimize our environmental footprint.
                </li>
                <li>
                  Dermatologist-Tested: Our formulations are tested and approved
                  by dermatologists to ensure safety and efficacy.
                </li>
                <li>
                  Innovation: We believe in transparency and regularly publish
                  our research findings to educate our customers about the
                  benefits and science behind our products.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
