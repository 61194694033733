import React, { useEffect } from "react";
import styles from "./contact.module.css";
// import locIcon from "../../assets/icons/location.svg";
// import mail from "../../assets/icons/mail.svg";
// import phone from "../../assets/icons/phone.svg";

function Contact() {
  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.caption}>CONNECT WITH US</div>
        <div className={styles.btnCont}>
          <button type="submit">
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={"mailto:info@farmroots.in"}
            >
              SEND ENQUIRY
            </a>
          </button>
        </div>
      </div>
    </>
  );
}

export default Contact;
