import React, { useEffect } from "react";
import styles from "./about.module.css";
import male from "../../assets/male.png";
// import female from "../../assets/female.png";
function About() {
  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.caption}>
          {" "}
          <strong></strong>{" "}
        </div>
        <div className={styles.story}>
          Welcome to Lailaah skincare At Lailaah we believe in the power of
          nature and science coming together to create skincare solutions that
          not only enhance your beauty but also nourish and protect your skin.
          Our mission is to empower individuals with the confidence that comes
          from having healthy, radiant skin.
        </div>
        <div style={{ width: "95%", margin: "auto", fontSize: "1.2rem" }}>
          <strong>Our Philosophy</strong>
        </div>
        <div className={styles.story}>
          Our philosophy is simple: clean, effective, and sustainable skincare.
          We are dedicated to using the finest natural ingredients, combined
          with the latest scientific advancements, to create products that
          deliver real results. Every product is meticulously crafted to ensure
          it is gentle, effective, and free from harmful chemicals.
        </div>
        <div className={styles.story}>
          From cleansers and toners to serums and moisturizers, our product line
          caters to all skin types and concerns. Whether you’re dealing with
          dryness, acne, aging, or sensitivity, we have something for you. Each
          product is designed to work harmoniously with your skin's natural
          processes, promoting balance and vitality.
        </div>
        <div className={styles.story}>
          <b>Key Features of Our Products:</b>
          Natural Ingredients: We source the highest quality botanical extracts
          and natural oils. Cruelty-Free: We are committed to cruelty-free
          practices and never test our products on animals. Eco-Friendly: Our
          packaging is recyclable, and we strive to minimize our environmental
          footprint. Dermatologist-Tested: Our formulations are tested and
          approved by dermatologists to ensure safety and efficacy. Innovation
          and Research: Innovation is at the heart of what we do. Our dedicated
          team of scientists and skincare experts continuously researches new
          ingredients and technologies to improve our formulations. We believe
          in transparency and regularly publish our research findings to educate
          our customers about the benefits and science behind our products.
        </div>

        <div className={styles.story}>
          <strong>Our Commitment</strong>
          We are committed to: Quality: Every product undergoes rigorous testing
          to ensure it meets our high standards. Sustainability: From our
          ingredients to our packaging, we prioritize sustainability in all
          aspects of our business. Customer Satisfaction: Your skin is unique,
          and we are here to help you find the perfect skincare routine. Our
          customer service team is always available to assist you with any
          questions or concerns. Join Our Community When you choose Lailaah
          you’re not just buying skincare products; you’re joining a community
          that values self-care, health, and environmental responsibility.
          Follow us on social media for skincare tips, product updates, and
          exclusive offers. Join the conversation with #YourCompanyHashtag and
          share your skincare journey with us.
        </div>
        <div className={styles.story}>
          Thank you for choosing Lailaah We look forward to being a part of your
          skincare routine and helping you achieve your best skin yet.
        </div>
        <div className={styles.caption}>
          <strong>Our Vision</strong>
        </div>
        <div className={styles.story}>
          To redefine beauty standards by providing inclusive skincare solutions
          that celebrate diversity and cater to the unique needs of
          every skin type."
        </div>
        <div className={styles.caption}>
          <strong>Our Founders</strong>
        </div>
        <div className={styles.foundersCont}>
          <div className={styles.founder}>
            <div className={styles.imgSection}>
              <img src={male} alt="male" />{" "}
            </div>
            <div className={styles.founderName}>Imran Alam</div>
            <div className={styles.aboutFounder}>
              A skilled business analyst with a robust background in marketing
              and social media marketing. With a keen analytical mind and a deep
              understanding of market dynamics, Excels at dissecting complex
              business problems and developing data-driven solutions that drive
              growth and efficiency. analytical acumen is complemented by their
              ability to translate data insights into actionable marketing
              plans. They are proficient in leveraging tools and platforms to
              track market trends, measure campaign performance, and optimize
              marketing efforts. Their strategic approach ensures that marketing
              initiatives are not only innovative but also aligned with business
              objectives.
            </div>
          </div>
          {/* <div className={styles.founder}>
            <div className={styles.imgSection}>
              <img src={male} alt="male" />{" "}
            </div>
            <div className={styles.founderName}>Anantrao Kulkarni</div>
            <div className={styles.aboutFounder}>
              For most of my career, I worked as a banker, crunching numbers and
              navigating the world of finance. In the course of my professional
              life, I witnessed the challenges faced by small-scale farmers.
              Despite producing top-notch goods, they were struggling to access
              global market. This struggle gave spark to the idea of “Farmroots”
              to bridge the gap between rural producers and global consumers and
              I along with my partners set out to streamline the export process
              for small-scale farmers, financially and logistically.
            </div>
          </div>
          <div className={styles.founder}>
            <div className={styles.imgSection}>
              <img src={female} alt="female" />{" "}
            </div>
            <div className={styles.founderName}>Dr. Lekha Padmaram, Ph.D.</div>
            <div className={styles.aboutFounder}>
              I hold a doctorate in Biotechnology with over 12 years of
              experience in agricultural research. And I have worked as a
              scientist at the International Crops Research Institute for the
              Semi-Arid Tropics (ICRISAT) and Consultant at the International
              Rice Research Institute (IRRI). My focus revolved around elevating
              crop productivity, improving nutritional quality, and fostering
              sustainable agriculture. With my extensive knowledge, expertise,
              and interest in the field, I strive to deliver agricultural
              products with the highest scientific and ethical standards.
            </div>
          </div>
          <div className={styles.founder}>
            <div className={styles.imgSection}>
              <img src={male} alt="male" />{" "}
            </div>
            <div className={styles.founderName}>Khudiram Khatua</div>
            <div className={styles.aboutFounder}>
              As they say once a farmer, always a farmer at heart. I am one such
              person who cannot spend a day without being in the field. Taking
              care of plants and the land gives me immense pleasure. With that
              in my heart, I have always been keen on starting this business of
              exporting great quality products.
            </div>
          </div> */}
        </div>
        {/* <div className={styles.caption}>
          <strong>Meet the Team</strong>
        </div>
        <div className={styles.teamCont}>
          <div className={styles.member}>
            <div className={styles.imgSection}>
              <img src={female} alt="female" />{" "}
            </div>
            <div className={styles.memberName}>Dr. Swarnika Vats</div>
            <div className={styles.aboutMember}>Communications Manager</div>
          </div>
          <div className={styles.member}>
            <div className={styles.imgSection}>
              <img src={female} alt="female" />{" "}
            </div>
            <div className={styles.memberName}>Madhusmita Dalai</div>
            <div className={styles.aboutMember}>House Manager</div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default About;
