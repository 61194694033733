import React, { useEffect } from "react";
import styles from "./certificates.module.css";
import gst from "../../assets/GST.pdf";
import udyam from "../../assets/Udyam.pdf";
function Certificates() {
  useEffect(() => {
    window.scrollTo({ top: "0" });
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div>
          <iframe src={gst} title="GST"></iframe>
        </div>
        <div>
          <iframe src={udyam} title="Udyam"></iframe>
        </div>
      </div>
    </>
  );
}

export default Certificates;
