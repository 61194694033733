import React from "react";
import styles from "./footer.module.css";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.upper}>
          <div className={styles.products}>
            <div className={styles.caption}>Products</div>
            <div className={styles.links}>
              <div>
                <Link className={styles.link} to={"spices"}>
                  &gt; Rice Facewash
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"dry-fruits"}>
                  &gt; Alovera Neem Facewash
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"pulses"}>
                  &gt; Face Scrub
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"cereals"}>
                  &gt; Moisturizer
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"cereals"}>
                  &gt; Sunscreen
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"cereals"}>
                  &gt; Night Cream
                </Link>
              </div>
              <div>
                <Link className={styles.link} to={"cereals"}>
                  &gt; Face Serum
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.address}>
            {/* <div className={styles.caption}>Stay Connected</div> */}
            <div className={styles.caption}>Rigestered address:</div>
            <div>
              khaneta Belaganj NH 83 Gaya patna Road
              <br />
              -Bihar 804403
            </div>
          </div>
          <div className={styles.number}>
            <div className={styles.caption}>Phone Numbers:</div>
            <div>+91 7091488602</div>
          </div>
          <div className={styles.mail}>
            <div className={styles.caption}>Email: </div>
            <div>Lailaahskincare@gmail.com</div>
          </div>
        </div>
        <div className={styles.middle}></div>
        <div className={styles.bottom}></div>
      </div>
    </>
  );
}

export default Footer;
